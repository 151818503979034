<template>
  <base-material-card
    icon="mdi-clipboard-text"
    :title="$t('menu.agentBanner.list')"
    class="px-5 py-3"
  >
    <div class="d-flex">
      <base-dropdown-filter
        :items="bannerTypeFilters"
        @changeFilter="changeBannerTypeFilter"
        style="flex: 0 0 50%; max-width: 50%"
        label="Tipe Banner"
      >
      </base-dropdown-filter>
      <base-dropdown-filter
        :items="availabilityStatusFilters"
        @changeFilter="changeAvailabilityStatusFilter"
        style="flex: 0 0 25%; max-width: 25%"
        label="Status Banner"
      >
      </base-dropdown-filter>
      <base-add-button
        :permissions="permissions"
        :basePath="basePath"
        :title="$t('agentBanner.btn.add')"
        style="flex: 0 0 25%; max-width: 25%"
      ></base-add-button>
    </div>
    <div class="mt-2">{{ $t('agentBanner.viewTotal') }}: {{ meta.total }}</div>
    <v-simple-table v-if="items && items.length > 0">
      <thead>
        <tr>
          <th class="text-center primary--text">
            {{ $t('general.actions') }}
          </th>
          <th class="primary--text">No.</th>
          <th class="primary--text">
            {{ $t('agentBanner.uuid') }}
          </th>
          <th class="primary--text">
            {{ $t('agentBanner.title') }}
          </th>
          <th class="primary--text">
            {{ $t('general.updatedBy') }}
          </th>
          <th class="primary--text">
            {{ $t('general.status') }}
          </th>
          <th class="primary--text">
            {{ $t('agentBanner.isTakeover') }}
          </th>
          <th class="primary--text">
            {{ $t('agentBanner.visitAmount') }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(item, index) in items" :key="`agent-banner-${index}`">
          <td>
            <div class="d-flex justify-center align-center">
              <base-edit-button
                :permissions="permissions"
                :basePath="basePath"
                :id="item.uuid"
              ></base-edit-button>
              <base-delete-button
                :permissions="permissions"
                :id="item.uuid"
                :showIcon="true"
                @deleteAction="deleteAction"
              ></base-delete-button>
            </div>
          </td>
          <td>{{ index + 1 }}</td>
          <td>{{ item.uuid }}</td>
          <td>{{ item.title }}</td>
          <td>{{ item.updated_by }}</td>
          <td>{{ item.status }}</td>
          <td>{{ item.is_takeover ? $t('general.yes') : $t('general.no') }}</td>
          <td>{{ item.visit_amount }}</td>
        </tr>
      </tbody>
    </v-simple-table>
    <pagination
      :push-state="false"
      :meta="meta"
      @changePage="changePage"
      :maxVisibleButtons="5"
      v-if="meta"
    />
  </base-material-card>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';
import { format } from 'date-fns';
const Pagination = () => import('@/components/utils/fractal-pagination');

export default {
  mixins: [HelperMixin],
  props: ['items', 'meta', 'permissions', 'bannerTypeFilters', 'availabilityStatusFilters'],
  components: {
    Pagination,
  },
  data() {
    return {
      format,
    };
  },
  computed: {
    ...mapState({
      basePath: (state) => state.banner.basePath,
    }),
  },
  methods: {
    changePage(page) {
      this.$emit('changePage', page);
    },
    changeBannerTypeFilter(val) {
      this.$emit('changeBannerTypeFilter', val);
    },
    changeAvailabilityStatusFilter(val) {
      this.$emit('changeAvailabilityStatusFilter', val);
    },
    async deleteAction(uuid) {
      try {
        await this.$store.dispatch('banner/delete', uuid);
        this.$swal(
          this.$i18n.t('general.success'),
          this.$i18n.t('agentBanner.delete.successMsg'),
          'success',
        );
        let query = this.$route.query;
        this.$store.commit('banner/RESET_DEFAULT');
        await this.$store.dispatch('banner/getInitData', query);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
  },
};
</script>
